<template>
<div>
    <span :style="{textAlign:'center', color:$vuetify.theme.themes.light.primary}" class="text-body-1 font-weight-bold" v-if="practiceCount && !hidePracticeCount">

        {{$t('attemptsLeft')}}
        {{practiceCount}}
      </span>
      <span :style="{textAlign:'center', color:$vuetify.theme.themes.light.primary}" class="text-body-1 font-weight-bold pl-2">
             <v-icon class="ml-2 mr-2" color="green">mdi-check-circle-outline</v-icon>
             <span v-if="hidePracticeCount">{{correctAttempts}}</span>
             <span v-if="!hidePracticeCount">{{totalAttempts - (practiceCount) - wrongAttempts}}</span>
      </span>
      <span :style="{textAlign:'center', color:$vuetify.theme.themes.light.primary}" class="text-body-1 font-weight-bold pl-2">
        <v-icon class="mr-2" color="primary">mdi-close-circle-outline</v-icon>
        {{wrongAttempts}}
      </span>
</div>
</template>
<script>
export default {
  props: ['practiceCount', 'totalAttempts', 'wrongAttempts', 'hidePracticeCount', 'correctAttempts']
}
</script>
