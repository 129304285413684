<template>
  <v-dialog v-model="visible" width="300">
    <template v-slot:activator="{ on, attrs }">
      <span
        :style="{
          textAlign: 'start',
          color: color || $vuetify.theme.themes.light.primary
        }"
        class="text-body-2"
        v-bind="attrs"
        v-on="on"
      >
        {{ !hideLabel ? $t('howToPlay') : '' }}

        <v-tooltip
          v-model="show"
          :top="$vuetify.breakpoint.smAndUp"
          :left="!$vuetify.breakpoint.smAndUp"
          content-class="bottom-arrow"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon :color="color || 'primary'" :size="iconSize || 'small'">
                mdi-information-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Instructions available here</span>
        </v-tooltip>
      </span>
    </template>

    <v-card>
      <v-card-title class="headline primary white--text">
        {{ $t('howToPlay') }}
      </v-card-title>

      <v-card-text class="mt-2">
        <p
          :style="{
            textAlign: 'start',
            color: $vuetify.theme.themes.light.primary
          }"
          class="text-body-2"
          v-html="$t(i18n_instructions_key)"
        ></p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="visible = false">
          {{ $t('OK') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ['i18n_instructions_key', 'hideLabel', 'color', 'iconSize'],
  data: () => ({
    visible: false,
    show: true
  }),
  created() {
    setTimeout(() => (this.show = false), 5000)
  }
}
</script>
<style lang="scss" scoped>
@import '../styles/global.scss';

$arrow-height: 16;
$arrow-width: 16;

.v-tooltip__content.menuable__content__active {
  color: var(--v-primary-base);
  background: white !important;
  opacity: 1;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  animation: fadeInOut 1.5s infinite;

  &.bottom-arrow {
    overflow: inherit !important; // that's important to make the pseudo-element visible outside the main container
    z-index: 20;

    &::before {
      z-index: 21;
      color: white;
      position: absolute;
      width: 16px !important;
      height: 16px !important;
      @include sm-up {
        content: '\25bc';
        top: calc(100% - 5px);
        bottom: 8px;
        left: calc(50% - 8px);
      }

      @include xs {
        content: '\25BA';
        top: calc(50% - 8px);
        bottom: 0px;
        left: calc(100% - 2px);
      }
    }
  }
  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: scale(1.1);
    }
    100% {
      opacity: 0;
    }
  }
}
</style>
